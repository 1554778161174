import React, { Component, Fragment } from "react"
import Layout from "../components/layout"
import ServicesSection from "../elements/service/Services"
import AboutSection from "../components/HomeLayout/homeOne/About"
import BlogContent from "../elements/blog/BlogContent"
import { videoTagString, VideoTag } from "react-video-tag"

videoTagString({ src: '/assets/video/bg-video.mp4', poster: '/assets/images/bg/bg-image-24.jpg' })

const SlideList = [
  {
      textPosition: 'text-left',
      category: '',
      title: 'Know. No. Fear.',
      description: 'Miniature painting instruction and professional painting services.',
      buttonText: 'Contact Us',
      buttonLink: '/contact'
  }
]

class IndexPage extends Component {
  constructor () {
      super()
      this.state = {
          isOpen: false
      }
      this.openModal = this.openModal.bind(this)
  }

  openModal () {
      this.setState({isOpen: true})
  }

  render(){
    const PostList = BlogContent.slice(0 , 3);
    return (
      <Layout>
        {/* <Helmet pageTitle="Studio Agency" />
        <Header /> */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
            {/* Start Single Slide */}
            {SlideList.map((value , index) => (
                <div className="slide slide-style-2 slider-video-bg d-flex align-items-center justify-content-center" key={index} data-black-overlay="6">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-8">
                                <div className={`inner ${value.textPosition}`}>
                                    {value.category ? <span>{value.category}</span> : ''}
                                    {value.title ? <h1 className="title">{value.title}</h1> : ''}
                                    {value.description ? <p className="description">{value.description}</p> : ''}
                                </div>
                            </div>
                            <div className="col-lg-4">
                                {/* <div className="video-inner">
                                    <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                    <button className="video-popup theme-color" onClick={this.openModal}><span className="play-icon"></span></button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    <div className="video-background">
                        <VideoTag autoPlay={`${true}`} muted={`${true}`} playsInline={`${true}`} loop={`${true}`}  src={`${"/assets/video/bg-video.mp4"}`} poster={`${"/assets/images/bg/bg-image-24.jpg"}`} />
                    </div>
                </div>
            ))}
            {/* End Single Slide */}

            
        </div>
        {/* End Slider Area   */}


        {/* Start About Area */}
        <div className="about-area ptb--120">
            <AboutSection image={this.props.data.aboutImage.childImageSharp.fluid} />
        </div>
        {/* End About Area */}

        {/* Start Service Area  */}
        <div className="service-area ptb--80  bg_image bg_image--3">
            <div className="container">
                <ServicesSection />
            </div>
        </div>
        {/* End Service Area  */}

        {/* Start Portfolio Area */}
        {/* <div className="portfolio-area ptb--120 bg_color--1">
            <div className="portfolio-sacousel-inner mb--55 mb_sm--30">
                <Portfolio />
            </div>
        </div> */}
        {/* End Portfolio Area */}

        {/* Start CounterUp Area */}
        {/* <div className="rn-counterup-area pt--25 pb--110 bg_color--1">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <h3 className="fontWeight500">Our Fun Facts</h3>
                        </div>
                    </div>
                </div>
                <CounterOne />
            </div>
        </div> */}
        {/* End CounterUp Area */}

        {/* Start Testimonial Area */}
        {/* <div className="rn-testimonial-area bg_color--5 ptb--120">
            <div className="container">
                <Testimonial />
            </div>
        </div> */}
        {/* End Testimonial Area */}

        {/* Start Blog Area */}
        <div className="rn-blog-area pt--120 bg_color--1">
            <div className="container">
                <div className="row align-items-end">
                    <div className="col-lg-6">
                        <div className="section-title text-left">
                            <h2>Latest News</h2>
                            <p>Get the latest info on what's going on what classes I have coming up, new products, and what I'm working on!</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="blog-btn text-left text-lg-right mt_sm--10 mt_md--10">
                            <a className="btn-transparent rn-btn-dark" href="/blog"><span className="text">View All News</span></a>
                        </div>
                    </div>
                </div>
                <div className="row mt--60 mt_sm--30">
                    {PostList.map((value , i ) => (
                        <div className="col-lg-4 col-md-6 col-12" key={i}>
                            <div className="blog blog-style--1">
                                <div className="thumbnail">
                                    <a href="/blog-details">
                                        <img className="w-100" src={`/assets/images/blog/blog-${value.images}.jpg`} alt="Blog Images"/>
                                    </a>
                                </div>
                                <div className="content">
                                    <p className="blogtype">{value.category}</p>
                                    <h4 className="title"><a href={`/blog-post-${value.id}`}>{value.title}</a></h4>
                                    <div className="blog-btn">
                                        <a className="rn-btn text-white" href={`/blog-post-${value.id}`}>Read More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
        {/* End Blog Area */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <BrandTwo />
                    </div>
                </div>
            </div>
        </div> */}
        {/* End Brand Area */}

        {/* Start Footer Area  */}
        {/* <FooterTwo /> */}
        {/* End Footer Area  */}

        {/* Start Back To Top */}
        {/* <div className="backto-top">
            <ScrollToTop showUnder={160}>
                <FiChevronUp />
            </ScrollToTop>
        </div> */}
        {/* End Back To Top */}
      </Layout>      
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query {
    aboutImage: file(relativePath: { eq: "about-pic-dark-angel.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`