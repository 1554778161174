import React, { Component } from "react"
import Img from "gatsby-image"

class About extends Component{
    render(){
        let title = 'About',
        description = 'I\'ve been addicted to collecting, painting, and playing games with tiny toy soldiers since the early 90\'s when I walked out of one of the first Games Workshops in the USA with my copy of the Warhammer 40,000 Rogue Trader rulebook. In the days since I\'ve spent countless hours honing my craft and enjoying my own miniature painting journey.' 
        return(
            <React.Fragment>
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center">

                            <div className="col-lg-5 col-md-12">
                                <div className="thumbnail">
                                    {/* <img className="w-100" src="/assets/images/about/about-3.jpg" alt="About Images"/> */}
                                    <Img fluid={this.props.image} />
                                </div>
                            </div>

                            <div className="col-lg-7 col-md-12">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">{title}</h2>
                                        <p className="description">{description}</p>
                                    </div>
                                    <div className="row mt--30 mt_sm--10">
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Instruction</h3>
                                                <p>I travel the world sharing what I've learned and guiding students along their own painting journeys.</p>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                                            <div className="about-us-list">
                                                <h3 className="title">Commissions</h3>
                                                <p>Occasionally I take on painting commissions. Get in touch via the contact form to learn more.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default About