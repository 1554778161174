const BlogContent = [
    {
        id: 1,
        images: 'adepticon-2020-classes',
        title: 'Adepticon 2020 Classes',
        category: 'Upcoming Classes'
    },
    {
        id: 2,
        images: 'virtual-coaching',
        title: 'Announcing Virtual Coaching!',
        category: 'Painting Instruction'
    },

    {
        id: 3,
        images: 'knf-tour',
        title: 'Know No Fear World Tour',
        category: 'Upcoming Classes'
    },
    // {
    //     images: '04',
    //     title: ' Getting tickets to the big show',
    //     category: 'Development'
    // },
    // {
    //     images: '01',
    //     title: 'A big ticket gone to be an interesting ',
    //     category: 'Management'
    // },
    // {
    //     images: '02',
    //     title: 'The Home of the Future Could Bebes',
    //     category: 'Design'
    // },
]

export default BlogContent;