import React, { Component } from "react";
import { FiCast , FiThumbsUp , FiUsers , FiGlobe } from "react-icons/fi";

const ServiceList = [
    {
        icon: <FiGlobe />,
        title: 'Painting Classes',
        description: 'I teach classes at gaming conventions and for bespoke groups all over the world.'
    },
    {
        icon: <FiUsers />,
        title: 'Virtual Instruction',
        description: 'Miniature painting coaching on your time without having to travel or organize a class.'
    },
    {
        icon: <FiThumbsUp />,
        title: 'Commission Painting',
        description: 'I\'m taking on commissions at various levels from armies to display pieces and even box art.'
    },
    // {
    //     icon: <FiMonitor />,
    //     title: 'Mobile App Development',
    //     description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
    // }
]

class ServiceTwo extends Component{
    render(){
        let title = 'Services',
        description = 'If interested in any of the services RogueShader Studios offers, get in touch today';
        return(
            <React.Fragment>
                <div className="row">
                    <div className="col-lg-4 col-12">
                        <div className="section-title mt--30 mt_md--5 mt_mobile--5 mb_mobile--10">
                            <h2 className="title">{title}</h2>
                            <p>{description}</p>
                            <div className="service-btn">
                                <a className="btn-transparent rn-btn-dark" href="#"><span className="text">Request Custom Service</span></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-12 mt_md--50">
                        <div className="row service-one-wrapper">
                            {ServiceList.map( (val , i) => (
                                <div className="col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                                    <a href="#">
                                        <div className="service service__style--2">
                                            <div className="icon">
                                                {val.icon}
                                            </div>
                                            <div className="content">
                                                <h3 className="title">{val.title}</h3>
                                                <p>{val.description}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceTwo;
